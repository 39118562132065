import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import Papercrete from '../Papercrete';
import Clipboard from '../Clipboard';


function PastProjects() {
    return (
      <>
      <Papercrete />
      <Clipboard />
      <Footer />
      </>
    );
  }
  
  export default PastProjects;