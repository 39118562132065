import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import MyceliumTitle from '../MyceliumTitle';
import Mycelium from '../Mycelium';


function MyceliumMycopack() {
    return (
      <>
      <MyceliumTitle />
      <Mycelium />
      <Footer />
      </>
    );
  }
  
  export default MyceliumMycopack;