import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import RecycleTitle from '../RecycleTitle';
import Recycle from '../Recylce';


function RecycledPaddleBoard() {
    return (
      <>
      <RecycleTitle />
      <Recycle />
      <Footer />
      </>
    );
  }
  
  export default RecycledPaddleBoard;